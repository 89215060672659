'use strict';

import { contactFormSubmission } from './components/marke-form';
import '../scss/main.scss';

const loadFormModals = () => {

    jQuery('.btn[data-toggle="modal"]').on('click', function() {
        var target = $(this).attr('data-target');
        jQuery('.modal[id="' + target + '"]').removeClass('fade').addClass('show');
    });

};

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        loadFormModals();
        contactFormSubmission();
    });
} else {
    loadFormModals();
    contactFormSubmission();
}