'use strict';

/**
 * Submit contact form using fetch to post form data.
 */
const contactFormSubmission = () => {
  document.querySelectorAll('.marke-form').forEach(function (form, idx) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      toggleSpinner(form);
      const url = new URL(process.env.FORM_SUBMIT_URL);
      const body = JSON.stringify({
        to: process.env.FORM_SUBMIT_TO,
        from: process.env.FORM_SUBMIT_FROM,
        subject: process.env.FORM_SUBMIT_SUBJECT,
        fields: getFormData(form),
        sendGridAPIKey: process.env.SENDGRID_API_KEY,
      });
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: body,
        }).then((res) => {
          if (res.status == 201) {
            toggleSpinner(form);
            createFormSuccessAlert(form, res);
            form.reset();
          } else {
            toggleSpinner(form);
            createFormErrorAlert(form, res);
          }
        });
    });
  });
};

/**
 * Creates an error alert.
 *
 * @param {Element} form
 * @param {Response} response
 */
const createFormErrorAlert = (form, response) => {
  let alert = document.createElement('p');
  alert.classList.add('alert', 'alert-danger', 'mt-3');
  alert.setAttribute('role', 'alert');
  alert.innerText = 'Houve um erro no envio do formulário!';
  form.querySelector('.form-alerts').appendChild(alert);
  form.querySelector('.form-alerts').style.display = 'block';
};

/**
 * Creates an succes alert.
 *
 * @param {Element} form
 * @param {Response} response
 */
const createFormSuccessAlert = (form, response) => {
  let alert = document.createElement('p');
  alert.classList.add('alert', 'alert-success', 'mt-3');
  alert.setAttribute('role', 'alert');
  alert.innerText = 'Contato recebido! Retornaremos em breve.';
  form.querySelector('.form-alerts').appendChild(alert);
  form.querySelector('.form-alerts').style.display = 'block';
};

/**
 * Toggles a status spinner.
 *
 * @param {Element} form
 */
const toggleSpinner = (form) => {
  let spinnerWrapper = form.querySelector('.form-alerts > .spinner-wrapper');
  if (spinnerWrapper) {
    form.querySelector('.form-alerts').removeChild(spinnerWrapper);
  } else {
    spinnerWrapper = document.createElement('div');
    spinnerWrapper.classList.add('spinner-wrapper', 'text-center');
    let spinner = document.createElement('div');
    spinner.classList.add('form-spinner', 'spinner-border');
    spinner.setAttribute('role', 'status');
    spinnerWrapper.appendChild(spinner);
    let spinnerSr = document.createElement('span');
    spinnerSr.classList.add('sr-only');
    spinnerSr.innerText = 'Enviando...';
    spinner.appendChild(spinnerSr);
    form.querySelector('.form-alerts').appendChild(spinnerWrapper);
    form.querySelector('.form-alerts').style.display = 'block';
  }
};

const getFormData = (form) => {
  const fields = Array.from(form.elements).filter((element) => !(element.type == 'submit'));
  let data = [];
  fields.forEach((field) => {
    if (!field.value) return;
    data.push({
      label: field.labels[0]?.innerHTML,
      value: field.value,
    });
  });
  return data;
};

export { contactFormSubmission };
